import * as React from "react";
import Layout from "../components/layout";
import { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as JsSearch from "js-search";
import SearchResults from "../components/Helpers/SearchResults";
const isBrowser = () => typeof window !== "undefined";
const language = isBrowser() && window.localStorage.getItem("language");
const Search = (props: any) => {
  const [allPostPages, setAllPostPages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState("");

  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        ...SearchPage
      }
      allWpPost {
        ...SearchPost
      }
    }
  `);
  useEffect(() => {
    var allPostsAndPages;
    if (data?.allWpPage?.nodes && data?.allWpPost.nodes) {
      allPostsAndPages = [...data.allWpPage.nodes, ...data.allWpPost.nodes];
    }
    allPostsAndPages = allPostsAndPages?.map((x: any) => {
      x["subpage_content"] = x?.educatorResourceCenterSubpage?.subPages
        ?.map((y: any) => y.content)
        .join(" ");
      x["subpage_titles"] = x?.educatorResourceCenterSubpage?.subPages
        ?.map((y: any) => y.title)
        .join(" ");
      return x;
    });
    setAllPostPages(allPostsAndPages);
    props.location.state && setQuery(props.location.state.search);
  }, [props.location.state]);
  useEffect(() => {
    const dataToSearch = new JsSearch.Search("id");
    /**
     *  defines a indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");
    dataToSearch.addIndex("content"); // sets the index attribute for the data
    dataToSearch.addIndex("title"); // sets the index attribute for the data
    dataToSearch.addIndex("subpage_content"); // sets the index attribute for the data
    dataToSearch.addIndex("subpage_titles"); // sets the index attribute for the data
    dataToSearch.addDocuments(allPostPages || []); // adds the data to be searched
    setSearchResults(dataToSearch.search(query));
  }, [allPostPages, query]);
  return (
    <Layout>
      <SearchResults results={searchResults} query={query}></SearchResults>
    </Layout>
  );
};
export default Search;
